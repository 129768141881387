<template>
  <div style="background-color: white;height: 100vh;">
    <div id="l-map" style="width: 0;height: 0;"></div>
    <div v-if="editAddress">
      <!-- <div v-if="data">
      <van-address-edit :area-list="areaList" show-delete show-set-default show-search-result
        :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" @delete="onDelete" :address-info="AddressInfo" />
    </div>
    <van-address-edit v-else :area-list="areaList" show-set-default show-search-result
      :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" /> -->
      <!-- :rules="[{ required: true }]" -->
      <van-form @submit="onSave" style="padding: 2rem 1rem; background-color: #fff;">
        <div style="display: flex;flex-direction: row;">
          <div style="flex:1">
            <span class="title"> 收货人</span>
            <van-field v-model="AddressInfo.name" placeholder="请输入姓名"
              style="border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem;" />
          </div>
        </div>
        <div>
          <div style="flex:1;margin-left: 0.5rem;">
            <span class="title">手机号码</span>
            <div
              style="border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem; display: flex;flex-direction: row;">
              <span
                style="height: 3rem;line-height: 3rem;margin-left: 1rem;padding-bottom: 0.1rem;font-size: 0.9rem;">+86</span>
              <van-field v-model="AddressInfo.tel" placeholder="请输入手机号码" style=" background-color: #00000000; " />
              <!-- :rules="[{ required: true }, { pattern: pattern, message: '手机号码格式错误！' }]" -->
            </div>
          </div>
        </div>
        <!-- <div>
        <span class="title"> 城市/街道</span>
        <van-field id="suggestId" v-model="AddressInfo.addressD" placeholder="请输入后选择"
          style=" border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem;">
        </van-field>
      </div> -->
        <div>
          <span class="title"> 选择省市区</span>
          <van-field v-model="AddressInfo.addressD" placeholder="请选择省市区" readonly @click="showSelect"
            style=" border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem;">
          </van-field>
        </div>
        <div @click="showAddress">
          <div class="title">选择地址</div>

          <!-- <div class="selece2" v-if="AddressInfo.addressDetail">
            <div>{{ AddressInfo.addressDetail }}</div>
            <div><van-icon name="arrow" /></div>
          </div>
          <div v-else class="selece2">
            <div>选择收货地址</div>
            <div><van-icon name="arrow" /></div>
          </div> -->
          <van-field @click="showAddress" :readonly="true" v-model="AddressInfo.addressDetail" placeholder="请选择收货地址"
            style="border-radius: 1rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem;" />
        </div>
        <div style="margin-top: 0.5rem;">
          <div class="title">门牌号</div>
          <van-field v-model="addressDetail" placeholder="填写详细地址，例：1号楼302"
            style="border-radius: 1rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 0.5rem;" />
        </div>
        <div id="searchResultPanel" style="border: 1px solid #c0c0c0;width: 100%;height: 3rem;display: none;"></div>
        <div style="font-size: 0.8rem;margin-top: 2rem;">
          <van-checkbox v-model="AddressInfo.isDefault" checked-color="#D33B2E" icon-size="1rem">设为默认地址</van-checkbox>
        </div>
        <div>
          <van-button class="foot-btn">保存</van-button>
        </div>
      </van-form>
      <van-popup v-model="show" round :style="{ height: '37%', width: '90%' }">
        <van-area title="请选择选择" :area-list="areaList" value="110101" @confirm="confirm" @cancel="cancel" />
      </van-popup>
    </div>
    <div v-else>
      <div style="padding: 1rem;">
        <span class="title">{{ AddressInfo.address }}</span>
        <van-field ref="field" @input="inputValue" v-model="AddressInfo.addressDetail" placeholder="请输入地址并且选择"
          style="border-radius: 2rem; background-color: #f8f8f8;margin-top: 0.5rem;" />
      </div>
      <div v-if="addressList" class="addressList">
        <div v-for="(item, index) in addressList" class="addressItem" @click="selectAddress(item)">
          <div> {{ item.title }} </div>
          <div class="address"> {{ item.address }} </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import area from "../../lip/area";
import VirtualKeyboard from '../../utils/virtualkeyboard'  //这是自己的路径
export default {
  name: "",
  props: {},
  data() {
    return {
      show: false,
      // 选择时出现的地区
      userId: '',
      areaList: area,
      AddressInfo: {},
      data: null,
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
      autocomplete: null,
      local: null,
      map: null,
      addressList: [],
      checked: false,
      editAddress: true,
      addressDetail: ''
    };
  },
  components: {},
  methods: {
    selectAddress(item) {
      console.log('_this.addressList---item  ', item);
      this.getCtyy(item)
    },
    getCtyy(item) {
      let this_ = this
      var myGeo = new BMapGL.Geocoder();
      // 根据坐标得到地址描述    
      myGeo.getLocation(item.point, function (result) {
        if (result.addressComponents.district !== this_.AddressInfo.address) {
          this_.showDialog(result)
        } else {
          this_.AddressInfo.lat = item.point.lat
          this_.AddressInfo.lng = item.point.lng
          this_.AddressInfo.addressDetail = item.title
          this_.editAddress = true
        }
      });
    },
    showDialog(result) {
      let this_ = this
      this.$Dialog.confirm({
        title: "提示",
        message: "所选地址与省市区不一致，如果继续将替换省市区",
        confirmButtonText: '继续',
        cancelButtonText: '取消'
      })
        .then(() => {
          this_.AddressInfo.addressD = result.addressComponents.province + result.addressComponents.city
            + result.addressComponents.district
          this_.AddressInfo.city = result.addressComponents.province
          this_.AddressInfo.district = result.addressComponents.city
          this_.AddressInfo.address = result.addressComponents.district
          this_.editAddress = true
        })
        .catch(() => {

        });
    },
    cancel() {
      this.show = false
    },
    showSelect() {
      this.show = true
    },
    showAddress() {
      if (this.AddressInfo.addressD == undefined || this.AddressInfo.addressD == '') {
        this.$Toast('请先选择省市区')
        return
      }
      this.editAddress = false
      this.$nextTick(() => {
        this.$refs.field.focus();
      })

    },
    inputValue() {
      this.searchMap(this.AddressInfo.addressDetail);
    },
    searchMap(val) {
      let _this = this
      let searchValue = this.AddressInfo.addressD + val
      var map = new BMapGL.Map("l-map");
      var local = new BMapGL.LocalSearch(map, {
        onSearchComplete: myFun,
      });
      function myFun() {
        _this.addressList = local.getResults()._pois
        console.log('_this.addressList  ', local.getResults());
        _this.AddressInfo.lat = local.getResults().getPoi(0).point.lat
        _this.AddressInfo.lng = local.getResults().getPoi(0).point.lng
      }
      local.search(searchValue);
    },
    confirm(val) {
      this.AddressInfo.addressD = ''
      val.forEach((element, index) => {
        this.AddressInfo.addressD = this.AddressInfo.addressD + element.name
        if (index == 0) {
          this.AddressInfo.city = element.name
        } else if (index == 1) {
          this.AddressInfo.district = element.name
          this.AddressInfo.cityCode = element.code
        } else {
          this.AddressInfo.address = element.name
        }

      });
      this.show = false
    },
    initMap() {
      let this_ = this
      var map = new BMapGL.Map("l-map");
      this.autocomplete = new BMapGL.Autocomplete({
        //建立一个自动完成的对象
        'input': "suggestId",
      });
      this.autocomplete.addEventListener("onhighlight", function (e) {
        //鼠标放在下拉列表上的事件
        var str = "";
        var _value = e.fromitem.value;
        var value = "";
        if (e.fromitem.index > -1) {
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str =
          "FromItem<br />index = " +
          e.fromitem.index +
          "<br />value = " +
          value;

        value = "";
        if (e.toitem.index > -1) {
          _value = e.toitem.value;
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str +=
          "<br />ToItem<br />index = " +
          e.toitem.index +
          "<br />value = " +
          value;
        document.getElementById("searchResultPanel").innerHTML = str;
      });
      let myValue;
      this.autocomplete.addEventListener("onconfirm", function (e) {
        //鼠标点击下拉列表后的事件
        var _value = e.item.value;
        myValue =
          _value.province +
          _value.city +
          _value.district +
          _value.street +
          _value.business;
        myValue;
        this_.AddressInfo.addressD = myValue
        this_.AddressInfo.address = _value.business
        this_.AddressInfo.city = _value.city
        this_.AddressInfo.district = _value.district
        setPlace();
      });

      function setPlace() {
        map.clearOverlays(); //清除地图上所有覆盖物
        function myFun() {
          this_.AddressInfo.lat = local.getResults().getPoi(0).point.lat
          this_.AddressInfo.lng = local.getResults().getPoi(0).point.lng
        }
        var local = new BMapGL.LocalSearch(map, {
          //智能搜索
          onSearchComplete: myFun,
        });
        local.search(myValue);
      }
    },
    // 返回
    goback() {
      this.$router.back();

    },
    searApi(str) {
      this.$api
        .getBDAddress(str, this.AddressInfo.city)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSave() {
      var timer = setInterval(() => {
        this.onSaveAddress()
        clearInterval(timer);
      }, 500)
    },
    validatePhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return false;
      }
      if (phoneNumber.length !== 11) {
        return false;
      }
      var reg = /^1\d{10}$/;
      return reg.test(phoneNumber);
    },

    isEmp(str) {
      if (!str) {
        return true;
      }
      if (str == undefined || str == '') {
        return true;
      }
    },
    // 保存的事件
    onSaveAddress() {
      if (this.isEmp(this.AddressInfo.name)) {
        this.$Toast('请输入姓名');
        return
      }
      let result = this.validatePhoneNumber(this.AddressInfo.tel)
      if (!result) {
        this.$Toast('请输入正确格式手机号');
        return
      }
      if (this.isEmp(this.AddressInfo.addressD)) {
        this.$Toast('请选择省市区');
        return
      }
      if (this.isEmp(this.AddressInfo.addressDetail)) {
        this.$Toast('填写城市街道时，选择下拉具体地址');
        return
      }
      if (this.isEmp(this.addressDetail)) {
        this.$Toast('请填写详细地址');
        return
      }

      let args;
      // 是否有传值 修改 还是新建
      if (this.data) {
        args = {
          id: this.AddressInfo.id,
          recipient: this.AddressInfo.name,
          recipientMobile: this.AddressInfo.tel,
          defaultFlag: this.AddressInfo.isDefault ? "1" : "0",
          address: this.AddressInfo.addressDetail,
          houseNum: this.addressDetail,
          lat: this.AddressInfo.lat,
          lng: this.AddressInfo.lng,
          addressPname: this.AddressInfo.address,
          addressCname: this.AddressInfo.city,
          addressCCode: this.AddressInfo.cityCode,
          addressQname: this.AddressInfo.district,
          userId: this.userId,
          status: '1'
        };

        this.$api
          .updateAddress({ ...args })
          .then((res) => {
            this.$Toast(res.msg);
            if (res.code == 1) {
              return
            }
            this.goback()
          })
          .catch((err) => {
            console.log(err);
          });

      } else {
        args = {
          recipient: this.AddressInfo.name,
          recipientMobile: this.AddressInfo.tel,
          defaultFlag: this.AddressInfo.isDefault ? "1" : "0",
          address: this.AddressInfo.addressDetail,
          houseNum: this.addressDetail,
          lat: this.AddressInfo.lat,
          lng: this.AddressInfo.lng,
          addressPname: this.AddressInfo.address,
          addressCname: this.AddressInfo.city,
          addressCCode: this.AddressInfo.cityCode,
          addressQname: this.AddressInfo.district,
          userId: this.userId,
          status: '1'
        };

        this.$api
          .addAddress({ ...args })
          .then((res) => {

            this.$Toast(res.msg);
            if (res.code == 1) {
              return
            }
            this.goback()
          })
          .catch((err) => {
            console.log(err);
          });
      }

    },
  },
  mounted() {
    // this.getBaiduAddress();
    // this.initMap()
    if (localStorage.getItem("addressId")) {
      this.data = JSON.parse(localStorage.getItem("addressId"));
      this.AddressInfo = {
        id: this.data.id,
        name: this.data.recipient,
        tel: this.data.recipientMobile,
        isDefault: this.data.defaultFlag == "1" ? true : false,
        addressDetail: this.data.address,
        lat: this.data.lat,
        lng: this.data.lng,
        address: this.data.addressPname,
        city: this.data.addressCname,
        cityCode: this.data.addressCCode,
        district: this.data.addressQname,
        addressD: this.data.addressCname + this.data.addressQname + this.data.addressPname
      }
      this.addressDetail = this.data.houseNum
      localStorage.removeItem("addressId");
    }
    localStorage.getItem("userInfo")
      ? (this.userId = JSON.parse(localStorage.getItem("userInfo")).id)
      : "";
    // this.virtualKeyboard = new VirtualKeyboard() //创建对象
    // this.virtualKeyboard.onStart()    //开始监听
    // //监听虚拟键盘弹出事件
    // this.virtualKeyboard.onShow(() => {
    //   //处理事件  
    // })
    // //监听键盘收起的事件
    // this.virtualKeyboard.onHidden(() => {
    //   if (!this.editAddress) {
    //     var timer = setInterval(() => {
    //       this.editAddress = true
    //       clearInterval(timer);
    //     }, 200)
    //   }

    // })
  },
  computed: {},
  watch: {},
  destroyed() {
    // 一般写在页面卸载或组件卸载的生命周期
    // this.virtualKeyboard.onEnd()
  },


};
</script>

<style lang='scss' scoped>
.van-switch--on {
  background-color: red;
}

.foot-btn {
  width: 100%;
  margin-top: 3rem;
  height: 3rem;
  border-radius: 2rem;
  line-height: 3rem;
  font-size: 1.2rem;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  color: #f5f5f5;
  text-align: center;
}

.title {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem 1.1rem;
  overflow: hidden;
  color: #323233;
  font-size: 1rem;
  line-height: 6.4vw;
  background-color: #fff;
}

.select {
  display: flex;
  flex-direction: row;
  height: 2rem;

  .selece2 {
    display: flex;
    margin-left: 3rem;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #AAA;
    flex: 1;
    color: #666;
  }
}

.addressList {
  padding: 0.5rem;
  height: 20rem;
  overflow-y: scroll;

  .addressItem {
    border-bottom: 0.5px solid #eee;
    padding: 0.5rem;

    .title {}

    .address {
      color: #666;
      font-size: 0.85rem;
    }
  }
}
</style>